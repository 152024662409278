/** @jsx jsx */
import { jsx, Spinner, Text } from "theme-ui";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

import { get } from "sites-common/utils/lodash";
import { Pivot, PivotItem, Stack } from "office-ui-fabric-react";
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { formatSys2OdooDate } from "sites-common/utils/nextMonth";
import { validateAbhyasiId } from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import Counters from "./counters";
import Notes from "./notes";

const collection = `PreceptorSpecialSittings2022Index`;
const storeAs = "pss2i";

const listenerSettings1 = () => ({
  collection,
  storeAs,
  doc: "index",
});

function Records() {
  const firestore = useFirestore();
  const indexData = useSelector((state) =>
    get(state.firestore.data, [storeAs], {})
  );

  useEffect(() => {
    const listenerSettings = listenerSettings1();
    firestore.setListener(listenerSettings);
    return function cleanup() {
      firestore.unsetListener(listenerSettings);
    };
  }, [firestore]);

  const groups = indexData ? Object.keys(indexData) : [];

  const [sittingDate, setSittingDate] = useState(
    formatSys2OdooDate(new Date())
  );
  const [groupKey, setGroupKey] = useState("sitting_date");
  const [abhyasiId, setAbhyasiId] = useState("");

  let groupValue = sittingDate;

  if (groupKey === "abhyasi_id" && validateAbhyasiId(abhyasiId)) {
    groupValue = abhyasiId;
  }

  return (
    <div>
      <div sx={{ textAlign: "center", m: 2 }}>
        <Text variant="header">Preceptors Special Sittings - 2022</Text>
        <br />
        <Text variant="subtitle">
          Tuesdays and Fridays at 5:30am local time
        </Text>
        <br /> <hr />
      </div>
      <div sx={{ p: 2 }}>
        {indexData === undefined && <Spinner />}
        {indexData !== undefined && (
          <div>
            {groups.length === 0 && (
              <div sx={{ textAlign: "center", m: 2 }}>
                <Text variant="description"> No Entries Available </Text>
              </div>
            )}
            {groups.length > 0 && (
              <Pivot>
                <PivotItem headerText="Counters">
                  <Counters indexData={indexData} />
                </PivotItem>
                <PivotItem headerText="Notes">
                  <Stack horizontal gap={5}>
                    <DynamicField
                      props={{}}
                      options={[
                        { name: "sitting_date", label: "Sitting Date" },
                        { name: "abhyasi_id", label: "Abhyasi Id" },
                      ]}
                      type="select"
                      name="groupKey"
                      value={groupKey}
                      label=""
                      onChange={setGroupKey}
                    />

                    {groupKey === "sitting_date" && (
                      <div sx={{ width: "150px" }}>
                        <DynamicField
                          props={{}}
                          type="date"
                          name="groupValue"
                          value={sittingDate}
                          label=""
                          onChange={setSittingDate}
                        />
                      </div>
                    )}
                    {groupKey === "abhyasi_id" && (
                      <div sx={{ width: "150px" }}>
                        <DynamicField
                          type="abhyasiid"
                          name="groupValue"
                          value={abhyasiId}
                          label=""
                          onChange={setAbhyasiId}
                        />
                      </div>
                    )}
                  </Stack>

                  {groupValue && (
                    <Notes
                      key={groupValue}
                      groupKey={groupKey}
                      groupValue={groupValue}
                    />
                  )}
                </PivotItem>
              </Pivot>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Records;
