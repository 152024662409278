/** @jsx jsx */
import { Grid, jsx, Spinner } from "theme-ui";
import PropTypes from "prop-types";
import { doFormatShortDate } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import { useMemo } from "react";
import { orderBy } from "sites-common/utils/lodash";

const TR = ([n, c]) => (
  <tr>
    <td sx={{ p: 1 }}>{n}</td>
    <td sx={{ p: 1 }}>
      <b>{c}</b>
    </td>
  </tr>
);

function Counters({ indexData }) {
  const places = useMemo(() => {
    const x = Object.entries(indexData)
      .filter((r) => r[0].includes("place_"))
      .map((r) => [r[0].replace("place_", ""), r[1]]);
    return orderBy(x, (r) => r[1], "desc");
  }, [indexData]);
  const dates = useMemo(() => {
    const x = Object.entries(indexData)
      .filter((r) => r[0].includes("date_"))
      .map((r) => [r[0].replace("date_", ""), r[1]]);

    return orderBy(x, (r) => r[0], "desc");
  }, [indexData]);

  return (
    <div>
      <div sx={{ p: 2 }}>
        {indexData === undefined && <Spinner />}
        {indexData !== undefined && (
          <Grid columns={3}>
            <div>
              <b>India - States</b>
              <table>
                <tbody>
                  {places
                    .filter(([r]) => r.split(" ").includes("India"))
                    .map(TR)}
                </tbody>
              </table>
            </div>
            <div>
              <b>Country</b>
              <table>
                <tbody>
                  {places
                    .filter(([r]) => !r.split(" ").includes("India"))
                    .map(TR)}
                </tbody>
              </table>
            </div>
            <div>
              <b variant="title">By Date</b>
              <table>
                <tbody>
                  {dates.map(([n, c]) => [doFormatShortDate(n), c]).map(TR)}
                </tbody>
              </table>
            </div>
          </Grid>
        )}
      </div>
    </div>
  );
}

Counters.propTypes = {
  indexData: PropTypes.shape({
    place: PropTypes.shape({}),
    sitting_date: PropTypes.shape({}),
  }).isRequired,
};

export default Counters;
