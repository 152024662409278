/** @jsx jsx */
import { Box, Flex, Grid, Image, jsx, Spinner, Text } from "theme-ui";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import AbhyasiEntity from "gatsby-plugin-hfn-profile/components/SrcmEntities/AbhyasiEntity";
import { DefaultButton } from "office-ui-fabric-react";

import PropTypes from "prop-types";
import { doFormatShortDate } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import Modal from "gatsby-plugin-hfn-profile/components/Modal";
import useMyBoolean from "sites-common/hooks/useMyBoolean";

const collection = () => `PreceptorSpecialSittings2022`;

const listenerSettingsDate = (d, s, key) => ({
  collection: collection(),
  storeAs: s,
  where: [key, "==", d],
});

function PictureModal({ downloadURL, fileName }) {
  const [show, { setFalse, setTrue }] = useMyBoolean(false);
  const [loading, { setFalse: hideLoading }] = useMyBoolean(true);
  const nparts = fileName.split(".");
  const lastpart = nparts[nparts.length - 1];

  const isImage = ["jpg", "png", "jpeg", "gif"].includes(
    lastpart.toLowerCase()
  );

  const btnProps = isImage
    ? { onClick: setTrue }
    : { href: downloadURL, target: "_blank" };

  return (
    <React.Fragment>
      <DefaultButton
        sx={{ m: 2 }}
        iconProps={{ iconName: "Assign" }}
        text="Diary Snapshot"
        {...btnProps}
      />
      {show && (
        <Modal handleClose={setFalse}>
          {loading && <Text>Loading ... </Text>}
          <Image
            onLoad={hideLoading}
            sx={{ maxHeight: "80vh", maxWidth: "80vw" }}
            alt="Diary Note"
            src={downloadURL}
          />
        </Modal>
      )}
    </React.Fragment>
  );
}

PictureModal.propTypes = {
  downloadURL: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

function MyRecordsOpen({ groupKey, groupValue }) {
  const groupString = `sittings${groupValue}`;
  const filteredRows = useSelector((state) =>
    groupString in state.firestore.ordered
      ? state.firestore.ordered[groupString]
      : undefined
  );

  const firestore = useFirestore();

  useEffect(() => {
    const listenerSettings = listenerSettingsDate(
      groupValue,
      groupString,
      groupKey
    );
    if (groupValue) {
      firestore.setListener(listenerSettings);
    }
    return function cleanup() {
      if (groupValue) {
        firestore.unsetListener(listenerSettings);
      }
    };
  }, [firestore, groupValue, groupString, groupKey]);

  return (
    <Grid gap={2} columns={[1, 2, 3, 4, 5]}>
      {filteredRows === undefined && <Spinner />}
      {Array.isArray(filteredRows) && filteredRows.length === 0 && (
        <Text variant="description" sx={{ m: 2 }}>
          {" "}
          No records found
        </Text>
      )}
      {Array.isArray(filteredRows) &&
        filteredRows.map((r) => {
          return (
            <Flex
              key={r.id}
              sx={{ flexDirection: "column", bg: "#fbeee6", p: 2, my: 2 }}
            >
              <Box>
                <Text variant="printFormLabel">
                  {doFormatShortDate(r.sitting_date)}
                </Text>
              </Box>

              <Box sx={{ flex: "1 1 auto", p: 1 }}>
                {!!r.picture && (
                  <PictureModal
                    downloadURL={r.picture.downloadURL}
                    fileName={r.picture.fileName}
                  />
                )}
                {!r.picture && r.notes}
              </Box>
              <Box>
                <small sx={{ color: "#fbeee6" }}>{r.id}</small>
                <AbhyasiEntity
                  abhyasiId={r.abhyasi_id}
                  display={
                    !r.name ? (
                      r.abhyasi_id
                    ) : (
                      <span>
                        <Text variant="printFormValue">{r.name}</Text>
                        &nbsp;&nbsp;
                        <Text variant="printFormLabel">
                          {r.city},{" "}
                          {r.country === "India" ? r.state : r.country}
                        </Text>
                      </span>
                    )
                  }
                />
              </Box>
            </Flex>
          );
        })}
    </Grid>
  );
}

MyRecordsOpen.propTypes = {
  groupValue: PropTypes.string.isRequired,
  groupKey: PropTypes.string.isRequired,
};

export default MyRecordsOpen;
