/** @jsx jsx */
import { jsx } from "theme-ui";
import DLWLayout from "../../../layouts/default";

import SpecialJournal from "../../../components/Trainer/SpecialJournal/admin";

function SpecialExercise() {
  return (
    <DLWLayout sx={{ bg: "white", pb: 4, mt: -3 }}>
      <SpecialJournal />
    </DLWLayout>
  );
}

export default SpecialExercise;
